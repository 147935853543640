

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Courses from './Component/Courses/Courses';
import Placements from './Component/Placements/Placements';
import Gallery from './Component/Gallery/Gallery';
import Certificate from './Component/Certificate/Certificate';
import Blogs from './Component/Blogs/Blogs';
import Contact from './Component/Contact/Contact';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import Navbar from './Component/Navbar/Navbar';
import FullStackDevelopment from './Component/Courses/FullStackDevelopment';
import WebDevelopment from './Component/Courses/WebDevelopment';
import PythonDevelopment from './Component/Courses/PythonDevelopment';
import AWS from './Component/Courses/AWS';
import DataAnalyst from './Component/Courses/DataAnalyst';
import DataScience from './Component/Courses/DataScience';
import SalesforceDevelopment from './Component/Courses/SalesforceDevelopment';
import SAP from './Component/Courses/SAP';
import NEET from './Component/Courses/NEET';
import Footer from './Component/Footer/Footer';
import College from './Component/Courses/College/College';
import Othere from './Component/Courses/College/Othere';
import MBA from './Component/Courses/College/MBA';
import BBA from './Component/Courses/College/BBA';
import MCA from './Component/Courses/College/MCA';
import BCA from './Component/Courses/College/BCA';
import BCOM from './Component/Courses/College/BCOM';

import TermsAndConditions from './Component/PrivacyPolicy/TermsAndConditions';
import PaymentAndRefundPolicy from './Component/PrivacyPolicy/PaymentAndRefundPolicy';

















const App = () => {



  

  



  return (
    
    <Router>
       <Navbar />
       
        <Routes>
          
          <Route path='/'Component={Home} />
          <Route path="/about" Component={About} />
          <Route path="/courses" Component={Courses} />
          <Route path="/placements" Component={Placements} />
          <Route path="/gallery" Component={Gallery} />
          <Route path="/certificate" Component={Certificate} />
          <Route path="/blogs" Component={Blogs} />
          <Route path="/contact" Component={Contact} />
          <Route path="/privacy-policy" Component={PrivacyPolicy} />




          <Route path="/college" Component={College} />







      <Route path="/1" Component={FullStackDevelopment} />
      <Route path="/2" Component={WebDevelopment} />
      <Route path="/3" Component={PythonDevelopment} />
      <Route path="/4" Component={AWS} />
      <Route path="/5" Component={DataAnalyst} />
      <Route path="/6" Component={DataScience} />
      <Route path="/7" Component={SalesforceDevelopment} />
      <Route path="/8" Component={SAP} />
      <Route path="/9" Component={NEET} />



      <Route path="/10" Component={MCA} />
      <Route path="/13" Component={BCA} />
      <Route path="/11" Component={MBA} />
      <Route path="/14" Component={BBA} />
      <Route path="/15" Component={BCOM} />


      <Route path="/12" Component={Othere} />


      <Route path="/privacy-policy" Component={PrivacyPolicy} />
      <Route path="/21" Component={TermsAndConditions} />
      <Route path="/22" Component={PaymentAndRefundPolicy} />




       
        </Routes>



<Footer/>

    </Router>
  
  );
};

export default App;
